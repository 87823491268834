// Typography
body {
  font-family: $font-body;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-heading;
  font-weight: $bold;
}

a {
  &:link,
  &:visited {
    color: $dark-red;
  }

  &:hover,
  &:active {
    text-decoration: none;
    color: $light-orange;
    transition: color 0.2s ease-out;
  }
}
