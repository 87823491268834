// Accent colors
$dark-red: #8e3343;
$light-orange: #ec9454;
$pale-yellow: #f1f08a;
$lime-green: #c6cd78;

// Monochromatic colors
$black: #000;
$light-gray: #f7f9fc;
$white: #fff;
