// Cookbooks List Styles
.cookbook-item {
  margin-bottom: 0.2em;
  padding-top: 1em;
  text-align: center;
  border: 2px solid $lime-green;
  border-radius: 10px;
}

.cookbook-item__image {
  width: 85%;
  margin-bottom: 1em;

  @media (min-width: $mq-xxsmall) {
    width: 50%;
  }

  @media (min-width: $mq-small) {
    float: left;
    margin: 0 0.2em 1em 1em;
  }
}

.cookbook-item__title {
  margin: 0 0.2em;
  font-size: 1.6em;
  font-weight: $bold;

  @media (min-width: $mq-xsmall) {
    font-size: 2em;
  }
}

.cookbook-item__author {
  font-size: 1.3em;
  font-weight: $bold;
  font-variant: small-caps;
}

.cookbook-item__date {
  font-size: 1em;
}

.cookbook-item__actions {
  padding: 0.3em 0 0.5em;
  font-size: 2em;

  .action__icon {
    margin-right: 0.3em;
    margin-left: 0.3em;
  }

  /* Hack the FA amazon icon to match same height as other actions */
  .action__icon--amazon {
    position: relative;
    top: 5px;
    font-size: 34px;
  }
}

// Cookbook Management
.cookbook-form {
  padding: 20px;
}

.add-button {
  padding-top: 5px;
}

.action-button {
  margin: 0.5em 0.2em 0;
}

.action-button__icon {
  width: auto;
  height: 1.5em;
}
